/* eslint-disable max-lines */
import { Article, Category, Translation } from '@wix/answers-api';
import { Box } from '@wix/design-system';
import { useExperiments, useHttpClient } from '@wix/fe-essentials-standalone';
import { useRouter } from 'next/router';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  BI,
  EXPERIMENTS,
  KNOWN_ISSUE_CATEGORY_ID,
} from '../../constants';
import { Context } from '../../context';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { pageClickParams } from '@wix/bi-logger-customer-care-data/v2/types';
import { useBI } from '../../hooks/useBI';
import {
  findCategoryInTree,
  getCategoriesBreadcrumb,
} from '../../utils/categories';
import { PAGES } from '../../types';
import { Breadcrumbs } from '../Breadcrumbs';
import { NavCategoryTree, NavigationTopics } from '../NavCategoryTree';
import css from './index.module.scss';
import { SubcategoryCard } from './SubcategoryCard/Index';
import { mapPageTypeToSourceName } from '../../utils/bi';
import { ArticleList } from './ArticleList';
import { animateScroll } from 'react-scroll';
import { StudioCategoryAddon } from './StudioCategoryAddon';
import { CategoryPageHeader } from './CategoryPageHeader';
import { EditorXNotification } from '../EditorXNotification';
import { useEditorXNotificationHeight } from '../../hooks/useEditorXNotificationHeight';
import { isMobile } from '../../utils/userAgent';
import { pushUrl } from '../InnerLink';

export type CategoryPageWrapperProps = {
  currentCategory: Category;
  categoriesArticles: { [key: string]: Article[] };
  categories: Category[];
};

export type LeftNavClickEventInfo = Pick<
  pageClickParams,
  | 'clicked_item_type'
  | 'clicked_url'
  | 'clicked_text'
  | 'clicked_item_order'
  | 'clicked_item_id'
  | 'category_order'
>;

export const WIX_STUDIO_CATEGORY_ID = '8557ec0a-42e4-48b4-a964-7df1f14df2e0';
export const WIX_EDITOR_X_CATEGORY_ID = '5a18e4be-dff2-4e6a-a4d1-7074b9c92df6';

export const CategoryPageWrapper: FunctionComponent<
  CategoryPageWrapperProps
> = ({ currentCategory, categoriesArticles, categories }) => {
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { pageTemplateData, setPageTemplateData } = useContext(Context);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState<Category>(currentCategory);
  const [showEditorXNotification, setShowEditorXNotification] = useState(true);
  const [categoryArticlesMap, setCategoryArticlesMap] = useState<{
    [key: string]: Article[];
  }>(categoriesArticles);
  const [editorXNotificationHeight] = useEditorXNotificationHeight();
  const context = useContext(Context);
  const httpClient = useHttpClient();
  const { experiments } = useExperiments({ readOnly: true });
  const isWixStudioCategory = selectedCategory.id === WIX_STUDIO_CATEGORY_ID;

  const navigationMenuClickBI = useCallback(
    async (itemClicked: LeftNavClickEventInfo) => {
      await sendBIEvent(
        pageClick({
          source_name: BI.SOURCE_NAMES.CATEGORY,
          kb_lang: locale as string,
          item_id: selectedCategory.id,
          ...itemClicked,
        })
      );
    },
    [locale, selectedCategory.id]
  );

  const onSelectKnownIssuesCategory = useCallback(
    async (knownIssuesCategory: Category, event?: LeftNavClickEventInfo) => {
      await pushUrl(`${locale}${knownIssuesCategory.uri}`, false, locale);
      if (event) {
        await navigationMenuClickBI(event);
      }
    },
    [locale, navigationMenuClickBI]
  );

  const fetchCategoryTranslations = useCallback(async (categoryId: string) => {
    try {
      const { data } = await httpClient.get<Translation[]>(
        '/api/category/translations',
        {
          params: {
            categoryId,
          },
        }
      );
      return data;
    } catch (error) {
      return [];
    }
  }, []);

  const onCategoryChange = useCallback(
    async (newCategory: Category, event?: LeftNavClickEventInfo) => {
      if (newCategory.id === KNOWN_ISSUE_CATEGORY_ID) {
        await onSelectKnownIssuesCategory(newCategory, event);
        return;
      }
      animateScroll.scrollToTop();
      hideCategoryTree();
      const translations = await fetchCategoryTranslations(newCategory.id);
      setPageTemplateData({
        ...pageTemplateData,
        itemId: newCategory.id,
        pageData: { category: newCategory, translations },
      });
      setSelectedCategory(newCategory);

      if (!newCategory.children || newCategory.children.length === 0) {
        await fetchArticles([newCategory]);
      } else if (newCategory.children) {
        await fetchArticles(newCategory.children);
      }
      if (event) {
        await navigationMenuClickBI(event);
      }
    },
    [pageTemplateData, setPageTemplateData, onSelectKnownIssuesCategory]
  );

  useEffect(() => {
    const onPopState = async (ev: PopStateEvent) => {
      const uri = ev.state.as.replace(`${locale}/`, '');
      if (uri !== selectedCategory.uri) {
        let decodeURI;
        try {
          decodeURI = decodeURIComponent(uri);
        } catch (error) {
          decodeURI = uri;
        }
        const category = findCategoryInTree(
          categories,
          `${decodeURI}`
        ) as Category;
        if (category) {
          await onCategoryChange(category);
        }
      }
    };
    addEventListener('popstate', onPopState);
    return () => removeEventListener('popstate', onPopState);
  }, [categories, selectedCategory.uri]);
  const onArticleClick = async (
    article: Article,
    event: LeftNavClickEventInfo
  ) => {
    hideCategoryTree();
    await navigationMenuClickBI(event);
  };
  const onNodeToggle = async (
    category: Category,
    event: LeftNavClickEventInfo
  ) => {
    await navigationMenuClickBI(event);
  };

  useEffect(
    () => () => {
      document.body.classList.remove('modal-open');
    },
    []
  );
  const getCategoryArticles = useCallback(
    async (categoryIds: string[]) => {
      const { data } = await httpClient.get<{ [key: string]: Article[] }>(
        '/api/category/articles',
        {
          params: {
            locale,
            summarize: true,
            categoryIds,
          },
        }
      );
      return data;
    },
    [httpClient, locale]
  );

  const subcategories = selectedCategory?.children?.filter(
    (child) => child.hasPublishedArticleDescendant
  );

  const fetchArticles = async (fetchCategories: Category[]) => {
    const childrenWithNoChildren = fetchCategories?.filter(
      (child) => !child.children || child.children.length === 0
    );
    const categoryIds = childrenWithNoChildren.map((child) => child.id);

    if (categoryIds.length === 0) {
      return {};
    }
    if (
      categoryIds.length > 1 &&
      categoryIds.every((categoryId) => categoryArticlesMap[categoryId])
    ) {
      return categoryIds.reduce(
        (acc, categoryId) => ({
          ...acc,
          [categoryId]: categoryArticlesMap[categoryId],
        }),
        {}
      ) as { [key: string]: Article[] };
    }
    const articles = await getCategoryArticles(categoryIds);
    setCategoryArticlesMap((prev) => ({ ...prev, ...articles }));

    return articles;
  };
  const topicsWithArticles = (
    categoriesToTransform: Category[]
  ): NavigationTopics[] =>
    categoriesToTransform
      .filter((cat) => cat.hasPublishedArticleDescendant)
      .map((categoryToTransform) => ({
        ...categoryToTransform,
        articles: async () =>
          categoryArticlesMap[categoryToTransform.id] ||
          (await fetchArticles([categoryToTransform]))[categoryToTransform.id],
        children:
          categoryToTransform.children &&
          topicsWithArticles(categoryToTransform.children),
      }));

  const toggleCategoryTree = () => {
    const classList = document.body.classList;
    if (isCategoryMenuOpen) {
      classList.remove('modal-open');
    } else {
      classList.add('modal-open');
    }
    setIsCategoryMenuOpen(!isCategoryMenuOpen);
  };
  const hideCategoryTree = () => {
    setIsCategoryMenuOpen(false);
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
  };
  const onLinkClick = useCallback(
    async (category: Category, clickedItemOrder?: number): Promise<void> => {
      await onCategoryChange(category, {
        clicked_item_type: BI.CLICKED_ITEM_TYPES.BREADCRUMBS,
        clicked_item_id: category.id,
        clicked_item_order: clickedItemOrder?.toString(),
        clicked_text: category.name,
        clicked_url: category.url,
      });
    },
    [onCategoryChange]
  );
  const onHomePageClick = useCallback(
    async (text: string) => {
      await sendBIEvent(
        pageClick({
          kb_lang: locale as string,
          source_name: mapPageTypeToSourceName(PAGES.CATEGORY),
          item_id: selectedCategory.id,
          clicked_item_type: BI.CLICKED_ITEM_TYPES.BREADCRUMBS,
          clicked_url: `${location.origin}/${locale}`,
          clicked_text: text,
        })
      );
    },
    [selectedCategory.id]
  );

  const breadcrumbItems = useMemo(
    () =>
      getCategoriesBreadcrumb(
        categories,
        selectedCategory.id,
        onLinkClick,
        true
      ),
    [selectedCategory, categories, onLinkClick]
  );

  const isEditorXCategory =
    selectedCategory.id === WIX_EDITOR_X_CATEGORY_ID &&
    showEditorXNotification &&
    experiments.enabled(EXPERIMENTS.SPECS.EDITOR_X_NOTIFICATION);

  return (
    <div className={css.categoryPage}>
      <Breadcrumbs
        breadcrumbItems={breadcrumbItems}
        onCategoryMenuClick={toggleCategoryTree}
        onHomePageClick={onHomePageClick}
        navCategoryTree={
          <NavCategoryTree
            onArticleClick={onArticleClick}
            isCategoryMenuOpen={isCategoryMenuOpen}
            onCategoryChange={onCategoryChange}
            topics={topicsWithArticles(categories)}
            selectedCategoryId={selectedCategory.id}
            onNodeToggle={onNodeToggle}
            onCategoryMenuClose={hideCategoryTree}
            locale={locale}
          />
        }
        shallow
      />
      <Box position="relative" direction="horizontal">
        <Box className={css.navCategoryTreeWrapper}>
          <NavCategoryTree
            onArticleClick={onArticleClick}
            isCategoryMenuOpen={isCategoryMenuOpen}
            onCategoryChange={onCategoryChange}
            topics={topicsWithArticles(categories)}
            selectedCategoryId={selectedCategory.id}
            onNodeToggle={onNodeToggle}
            onCategoryMenuClose={hideCategoryTree}
            locale={locale}
          />
        </Box>
        {subcategories && subcategories.length > 0 ? (
          <Box className={css.categoryDetailsWrapper} align="center">
            <Box direction="vertical">
              <CategoryPageHeader selectedCategory={selectedCategory} />
              {isEditorXCategory && (
                <Box className={css.floatingNotificationEditorX}>
                  <EditorXNotification
                    onClose={() => setShowEditorXNotification(false)}
                  />
                </Box>
              )}
              <div
                className={`${css.subcategories} ${
                  isEditorXCategory ? css.wixEditor : ''
                }`}
                style={
                  isEditorXCategory && isMobile(context)
                    ? { marginTop: `${editorXNotificationHeight + 24}px` }
                    : undefined
                }
              >
                {subcategories.map((subcategory, idx) => (
                  <Box
                    key={subcategory.id}
                    className={css.subcategory}
                    direction="vertical"
                  >
                    <SubcategoryCard
                      categoryOrder={idx + 1}
                      onClickSubcategory={onCategoryChange}
                      onClickArticle={onArticleClick}
                      category={subcategory}
                      articles={categoryArticlesMap[subcategory.id]}
                    />
                  </Box>
                ))}
              </div>
              {isWixStudioCategory ? (
                <div className={css.StudioCategoryAddonWrapper}>
                  <StudioCategoryAddon
                    categoryId={selectedCategory.id}
                    lastItemOrder={subcategories.length}
                  />
                </div>
              ) : null}
            </Box>
          </Box>
        ) : (
          <ArticleList
            category={selectedCategory}
            categoryArticles={categoryArticlesMap[selectedCategory.id]}
          />
        )}
      </Box>
    </div>
  );
};
