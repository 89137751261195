import { FunctionComponent } from 'react';
import { Box, SkeletonGroup, SkeletonLine, SkeletonRectangle } from '@wix/design-system';
import { DATA_HOOKS } from '../../../../../dataHooks';
import css from './index.module.scss';

export type ArticlesSkeletonLoadingProps = {
  numberOfArticles: number;
};

export const ArticlesSkeletonLoading: FunctionComponent<ArticlesSkeletonLoadingProps> = ({
  numberOfArticles
}) => {
  const ArrayRotate = new Array(numberOfArticles).fill(1);
  const widths = [150, 175, 200];

  return (
    <div className={css.skeletonLoading} data-hook={DATA_HOOKS.NAV_CATEGORY_TREE_ARTICLES_LOADING}>
      {ArrayRotate.map((_, index) => (
        <SkeletonGroup key={index} skin="light" className={css.skeletonGroup} dataHook={DATA_HOOKS.NAV_CATEGORY_TREE_ARTICLE_LOADING_SKELTON}>
          <Box direction='horizontal' marginTop='6px' verticalAlign='middle' align='left'>
            <SkeletonRectangle height='18px' width='18px' className={css.skeletonGroupIcon} />
            <SkeletonLine className={css.skeletonGroupLine} width={`${widths[(index % widths.length)]}px`} />
          </Box>
        </SkeletonGroup>
      ))}
    </div>
  );
};
