import { Article, ArticleType } from '@wix/answers-api';
import { Box, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import ArrowRightIcon from 'wix-ui-icons-common/ArrowRight';
import ArrowLeftIcon from 'wix-ui-icons-common/ArrowLeft';
import { FunctionComponent, useContext, useMemo } from 'react';
import { DATA_HOOKS } from '../../../../dataHooks';
import { InnerLink } from '../../../InnerLink';
import { ArticleMediumIcon } from '../../../../icons/ArticleMediumIcon';
import css from './index.module.scss';
import { useRouter } from 'next/router';
import { isRTL } from '../../../../utils/internationalization';
import { useEditorXNotificationHeight } from '../../../../hooks/useEditorXNotificationHeight';
import { isMobile } from '../../../../utils/userAgent';
import { Context } from '../../../../context';
import { TOOLTIP_ENTER_DELAY } from '../../../../constants';

export type ArticleListSectionProps = {
  articles: Article[];
  type: ArticleType;
  startIndex: number;
  onClick: (article: Article, order: number) => void;
  isEditorXSubCategory?: boolean;
};

export const ArticleListSection: FunctionComponent<ArticleListSectionProps> = ({
  articles,
  type,
  startIndex,
  onClick,
  isEditorXSubCategory,
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const isRTLLanguage = isRTL(locale);
  const context = useContext(Context);
  const [editorXNotificationHeight] = useEditorXNotificationHeight();

  const getArticleTypeTitle = useMemo((): string => {
    switch (type) {
      case ArticleType.KNOWN_ISSUE:
        return t('category.articles.type.known.issue');
      case ArticleType.FEATURE_REQUEST:
        return t('category.articles.type.feature.requests');
      default:
        return t('category.articles.type.articles');
    }
  }, [type, t]);

  return (
    <>
      {articles.length > 0 && (
        <Box
          direction="vertical"
          className={`${css.articles} ${
            isEditorXSubCategory ? css.wixEditor : ''
          }`}
          dataHook={DATA_HOOKS.CATEGORY_ARTICLES_SECTION}
          align="center"
          verticalAlign="middle"
          marginTop={
            isEditorXSubCategory && isMobile(context) && startIndex === 0
              ? `${editorXNotificationHeight + 24}px`
              : undefined
          }
        >
          <Box direction="vertical" className={css.articleLinks}>
            <Text
              className={css.articleType}
              dataHook={DATA_HOOKS.CATEGORY_ARTICLES_SECTION_TYPE}
            >
              {getArticleTypeTitle}
            </Text>
            {articles.map((article, idx) => (
              <li key={article.id}>
                <InnerLink
                  withEnterHandler
                  href={article.uri}
                  className={css.articleLink}
                  dataHook={DATA_HOOKS.CATEGORY_ARTICLES_SECTION_LINK}
                  onClick={() => onClick(article, startIndex + idx)}
                >
                  <Box
                    align="space-between"
                    verticalAlign="middle"
                    columnGap={24}
                    width="100%"
                  >
                    <Box gap="1">
                      <ArticleMediumIcon />
                      <Text
                        size="medium"
                        maxLines={2}
                        ellipsis
                        showTooltip={true}
                        tooltipProps={{
                          maxWidth: '720px',
                          enterDelay: TOOLTIP_ENTER_DELAY,
                        }}
                        className={css.articleTitle}
                      >
                        {article.title}
                      </Text>
                    </Box>
                    {isRTLLanguage ? <ArrowLeftIcon /> : <ArrowRightIcon />}
                  </Box>
                </InnerLink>
              </li>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
