import { Article, Category } from '@wix/answers-api';
import { BreadcrumbItem } from '../components/Breadcrumbs';
import { EMPTY_CATEGORY_ID } from '../constants';

export const treeTraversal = (
  tree: Category[],
  categoriesMap: Record<string, Category>
): void => {
  for (const category of tree) {
    categoriesMap[category.id] = category;
    if (category.children) {
      treeTraversal(category.children, categoriesMap);
    }
  }
};

export const findCategoryInTree = (
  tree: Category[],
  uri: string
): Category | undefined => {
  for (const category of tree) {
    if (category.uri === uri) {
      return category;
    }
    if (category.children) {
      const foundCategory = findCategoryInTree(category.children, uri);
      if (foundCategory) {
        return foundCategory;
      }
    }
  }
  return undefined;
};

export const mapArticlesToCategories = (
  articles: Article[],
  categoryIds: string[]
): Record<string, Article[]> => {
  const articlesMap: Record<string, Article[]> = {};
  for (const article of articles) {
    if (!articlesMap[article.categoryId]) {
      articlesMap[article.categoryId] = [];
    }
    articlesMap[article.categoryId].push(article);
  }
  categoryIds.forEach((categoryId) => {
    if (!articlesMap[categoryId]) {
      articlesMap[categoryId] = [];
    }
  });
  return articlesMap;
};

export const getCategoriesPaths = (
  categories: Category[],
  categoryIds: string[]
): Record<string, Category[]> => {
  const categoriesMap: Record<string, Category> = {};

  treeTraversal(categories, categoriesMap);

  const categoriesPaths: Record<string, Category[]> = {};
  for (const categoryId of categoryIds) {
    const categoryPath = [];
    let currentCategory = categoriesMap[categoryId];
    categoryPath.push(currentCategory);
    while (currentCategory && currentCategory.parentId !== EMPTY_CATEGORY_ID) {
      currentCategory = categoriesMap[currentCategory.parentId];
      categoryPath.push(currentCategory);
    }
    categoriesPaths[categoryId] = categoryPath.reverse();
  }

  return categoriesPaths;
};

export const getCategoriesBreadcrumb = (
  categories: Category[],
  categoryId: string,
  onClick: (category: Category, clickedItemOrder?: number) => Promise<void>,
  disableClickLastItem?: boolean
): BreadcrumbItem[] => {
  const categoriesPaths = getCategoriesPaths(categories, [categoryId]);
  const categoryPath = categoriesPaths[categoryId];
  if (categoryPath) {
    return categoryPath
      .filter((category) => !!category)
      .map((category, idx) => {
        const isLastItem = idx === categoryPath.length - 1;
        return {
          id: category.id,
          label: category.name,
          uri: disableClickLastItem && isLastItem ? undefined : category.uri,
          onClick: () => onClick(category, idx + 2),
        };
      });
  } else {
    return [];
  }
};
export const getCategoryById = (
  categories: Category[],
  categoryId: string
): Category | undefined => categories.find((item) => item.id === categoryId);

export const getCategoryByUri = (
  categories: Category[],
  uri: string
): Category | undefined => categories.find((item) => item.uri === uri);
