import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { Box, Heading, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { ExternalLink } from '@wix/wix-ui-icons-common';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useContext } from 'react';
import {
  BI,
  WIX_STUDIO_COMMUNITY_FORUM_LINK,
  WIX_STUDIO_SUPPORTED_LANGUAGES,
} from '../../../constants';
import { Context } from '../../../context';
import { DATA_HOOKS } from '../../../dataHooks';
import { useBI } from '../../../hooks/useBI';
import { isMobile } from '../../../utils/userAgent';
import css from './index.module.scss';

export const WIX_STUDIO_ACADEMY_LINK = 'https://www.wix.com/studio/academy';

export type StudioCategoryAddon = {
  lastItemOrder: number;
  categoryId: string;
};

export const StudioCategoryAddon: FunctionComponent<StudioCategoryAddon> = ({
  lastItemOrder,
  categoryId,
}) => {
  type StudioLink = 'community-forum' | 'academy';

  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const context = useContext(Context);

  const onClickStudioLink = async (
    clickedItem: StudioLink,
    isHeadingClick?: boolean
  ) => {
    const isCommunityForumClick = clickedItem === 'community-forum';
    const clickedText = isHeadingClick
      ? `category-page.studio-category-addon.${clickedItem}.title`
      : `category-page.studio-category-addon.${clickedItem}.cta-text`;

    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.CATEGORY,
        kb_lang: locale,
        item_id: categoryId,
        clicked_item_type: `wix studio ${
          isCommunityForumClick ? 'community forum' : 'academy'
        }`,
        clicked_url: isCommunityForumClick
          ? WIX_STUDIO_COMMUNITY_FORUM_LINK
          : WIX_STUDIO_ACADEMY_LINK,
        clicked_text: t(clickedText),
        clicked_item_order: `${lastItemOrder + (isCommunityForumClick ? 2 : 1)}`,
      })
    );
  };

  if (!WIX_STUDIO_SUPPORTED_LANGUAGES.includes(locale as string)) {
    return null;
  }

  return (
    <Box
      direction="horizontal"
      align="space-between"
      className={css.StudioCategoryAddonWrapper}
      dataHook={DATA_HOOKS.WIX_STUDIO_CATEGORY_ADDON}
    >
      <Box
        direction="vertical"
        className={`${css.StudioCategoryCard} ${css.StudioAcademy}`}
      >
        <Box
          className={`${css.StudioCategoryCardImage} ${css.AcademyCardImage}`}
          verticalAlign="middle"
        >
          <Heading className={css.AcademyTitle}>
            {t('category-page.studio-category-addon.title')}
          </Heading>
        </Box>
        <Box className={css.StudioCategoryCardRectanglesMobileOnly}>
          <Box className={css.StudioCategoryCardRectangle1} />
          <Box className={css.StudioCategoryCardRectangle2} />
          <Box className={css.StudioCategoryCardRectangle3} />
        </Box>
        <Box className={css.StudioCategoryCardContent} direction="vertical">
          <Link
            target="_blank"
            href={WIX_STUDIO_ACADEMY_LINK}
            onClick={() => onClickStudioLink('academy', true)}
          >
            <Heading
              ellipsis
              size="small"
              className={css.StudioCategoryCardTitle}
              dataHook={DATA_HOOKS.WIX_STUDIO_CATEGORY_ADDON_ACADEMY_TITLE}
            >
              {t('category-page.studio-category-addon.academy.title')}
            </Heading>
          </Link>
          <Text
            size="medium"
            weight="thin"
            className={css.StudioCategoryCardDescription}
          >
            {t('category-page.studio-category-addon.academy.description')}
          </Text>
          <TextButton
            as={'a'}
            href={WIX_STUDIO_ACADEMY_LINK}
            suffixIcon={<ExternalLink />}
            size={isMobile(context) ? 'small' : 'medium'}
            target="_blank"
            onClick={() => onClickStudioLink('academy')}
            dataHook={DATA_HOOKS.WIX_STUDIO_CATEGORY_ADDON_ACADEMY_LINK}
            className={css.StudioCategoryCardCTA}
          >
            {t('category-page.studio-category-addon.academy.cta-text')}
          </TextButton>
        </Box>
      </Box>

      <Box
        direction="vertical"
        className={`${css.StudioCategoryCard} ${css.StudioCommunityForum}`}
      >
        <Box className={css.StudioCategoryCardYellowCube}>
          <Image
            src={'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/studio-category-banner/y-cube-desktop.svg'}
            width={122}
            className={css.StudioCategoryCardYellowCubeDesktop}
            height={122}
            alt=""
            loading="lazy"
          />
          <Image
            src={'https://static.parastorage.com/services/kb-client-nextjs/dist/public/images/studio-category-banner/y-cube-mobile.svg'}
            width={100}
            className={css.StudioCategoryCardYellowCubeMobile}
            height={98.75}
            alt=""
            loading="lazy"
          />
          <Box className={css.StudioCategoryCardImageDarkCubeMobileOnly} />
        </Box>
        <Box className={css.StudioCategoryCardContent} direction="vertical">
          <Link
            target="_blank"
            href={WIX_STUDIO_COMMUNITY_FORUM_LINK}
            onClick={() => onClickStudioLink('community-forum', true)}
          >
            <Heading
              ellipsis
              size="small"
              className={css.StudioCategoryCardTitle}
              dataHook={
                DATA_HOOKS.WIX_STUDIO_CATEGORY_ADDON_COMMUNITY_FORUM_TITLE
              }
            >
              {t('category-page.studio-category-addon.community-forum.title')}
            </Heading>
          </Link>
          <Text
            size="medium"
            weight="thin"
            className={css.StudioCategoryCardDescription}
          >
            {t(
              'category-page.studio-category-addon.community-forum.description'
            )}
          </Text>
          <TextButton
            as="a"
            href={WIX_STUDIO_COMMUNITY_FORUM_LINK}
            suffixIcon={<ExternalLink />}
            size={isMobile(context) ? 'small' : 'medium'}
            target="_blank"
            onClick={() => onClickStudioLink('community-forum')}
            dataHook={DATA_HOOKS.WIX_STUDIO_CATEGORY_ADDON_COMMUNITY_FORUM_LINK}
            className={css.StudioCategoryCardCTA}
          >
            {t('category-page.studio-category-addon.community-forum.cta-text')}
          </TextButton>
        </Box>
      </Box>
    </Box>
  );
};
