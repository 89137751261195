export const ChevronRightBoldIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 12L10.5 9L7.5 6"
      stroke="currentcolor"
      strokeWidth="1.25"
      strokeLinecap="round"
    />
  </svg>
);
