import React, { FunctionComponent, useEffect, useState } from 'react';
import css from './index.module.scss';
import { useBI } from '../../../hooks/useBI';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { BI } from '../../../constants';
import { isRTL } from '../../../utils/internationalization';

export type ResizerHorizontalProps = {
  categoryId: string;
  elemRef?:
    | React.RefObject<HTMLDivElement>
    | React.MutableRefObject<HTMLDivElement>;
  locale?: string;
};

export const ResizerHorizontal: FunctionComponent<ResizerHorizontalProps> = ({
  categoryId,
  elemRef,
  locale,
}) => {
  const { sendBIEvent } = useBI();

  const [previousWidth, setPreviousWidth] = useState<string | undefined>();

  useEffect(() => {
    if (elemRef && elemRef.current) {
      setPreviousWidth(elemRef.current.getBoundingClientRect().width + 'px');
    }
  }, [elemRef]);

  const registerResizeEvents = () => {
    document.addEventListener('mousemove', onResize, false);
    document.addEventListener('mouseup', stopResizing, false);
  };

  const removeResizeEvents = () => {
    document.removeEventListener('mousemove', onResize, false);
    document.removeEventListener('mouseup', stopResizing, false);
  };

  const startResizing = (e: React.MouseEvent) => {
    e.stopPropagation();
    registerResizeEvents();
  };

  const resizeBIEvent = async () => {
    const previous = previousWidth && Number(previousWidth?.replace('px', ''));
    const current = Number(elemRef?.current?.style.width?.replace('px', ''));

    const isIncrease = !!(previous && current > previous);
    const event = isIncrease
      ? BI.CLICKED_ITEM_TYPES.RESIZE.INCREASE
      : BI.CLICKED_ITEM_TYPES.RESIZE.DECREASE;

    setPreviousWidth(elemRef?.current?.style.width);
    await sendBIEvent(
      pageClick({
        item_id: categoryId,
        source_name: BI.SOURCE_NAMES.CATEGORY,
        clicked_item_type: event,
        kb_lang: locale,
      })
    );
  };

  const stopResizing = async () => {
    removeResizeEvents();
    await resizeBIEvent();
  };

  const resize = (newWidth: number) => {
    if (elemRef?.current) {
      elemRef.current.style.width = `${newWidth}px`;
    }
  };

  const onResize = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (elemRef && elemRef.current) {
      {
        const newWidth = isRTL(locale)
          ? elemRef.current.getBoundingClientRect().right - e.clientX
          : e.clientX - elemRef.current.getBoundingClientRect().left;
        resize(newWidth);
      }
    }
  };

  return (
    <div className={css.resize} onMouseDown={startResizing}>
      <div className={css.resizePlaceholder}>
        <div className={css.resizeHandle} />
      </div>
    </div>
  );
};
