import { Article } from '@wix/answers-api';
import { FunctionComponent } from 'react';
import { Text } from '@wix/design-system';
import { DATA_HOOKS } from './../../../../dataHooks';
import { ArticlesSkeletonLoading } from './ArticlesSkeletonLoading';
import { InnerLink } from '../../../InnerLink';
import { ArticleSmallIcon } from '../../../../icons/ArticleSmallIcon';
import css from './index.module.scss';
import { TOOLTIP_ENTER_DELAY } from '../../../../constants';

export type CategoryArticlesProps = {
  categoryId: string;
  numberOfArticles: number;
  articles?: Article[];
  onArticleClick: (article: Article, order: number) => void;
};

export const CategoryArticles: FunctionComponent<CategoryArticlesProps> = ({
  categoryId,
  articles,
  numberOfArticles,
  onArticleClick,
}) => {
  const hasArticles = articles && articles.length > 0;

  return (
    <ul
      data-hook={DATA_HOOKS.NAV_CATEGORY_TREE_ARTICLES}
      key={`${categoryId}-articles`}
      className={`${css.CategoryArticles}`}
    >
      {hasArticles ? (
        articles.map((article, idx) => (
          <li
            tabIndex={0}
            key={article.id}
            data-hook={`${DATA_HOOKS.NAV_CATEGORY_TREE_ARTICLE}`}
          >
            <InnerLink
              href={article.uri}
              withEnterHandler
              onClick={() => onArticleClick(article, idx + 1)}
              className={css.categoryArticle}
            >
              <ArticleSmallIcon />
              <Text
                size="small"
                tooltipProps={{ enterDelay: TOOLTIP_ENTER_DELAY }}
                className={css.name}
                ellipsis
                maxLines={3}
              >
                {article.title}
              </Text>
            </InnerLink>
          </li>
        ))
      ) : (
        <ArticlesSkeletonLoading numberOfArticles={numberOfArticles} />
      )}
    </ul>
  );
};
