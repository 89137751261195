import React, { FunctionComponent } from 'react';
import { Article, ArticleType, Category } from '@wix/answers-api';
import { Box, Heading, Loader } from '@wix/design-system';
import { DATA_HOOKS } from '../../../dataHooks';
import { ArticleListSection } from './ArticleListSection';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import css from './index.module.scss';
import { useBI } from '../../../hooks/useBI';
import { BI, EXPERIMENTS } from '../../../constants';
import { useRouter } from 'next/router';
import { WIX_EDITOR_X_CATEGORY_ID } from '..';
import { EditorXNotification } from '../../EditorXNotification';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EmptyCategoryState } from '../EmptyCategoryState';
import { isMonthOldLaunchedFr } from '../../../utils/isMonthOldLaunchedFr';

export type ArticleListProps = {
  category: Category;
  categoryArticles?: Article[];
};

export const ArticleList: FunctionComponent<ArticleListProps> = ({
  category,
  categoryArticles,
}) => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const [showEditorXNotification, setShowEditorXNotification] =
    React.useState(true);

  const { experiments } = useExperiments({ readOnly: true });
  const isExcludeLaunchedFRsEnabled = experiments.enabled(EXPERIMENTS.SPECS.EXCLUDE_LAUNCHED_FRS);
  const isEditorXSubCategory =
    category.parentId === WIX_EDITOR_X_CATEGORY_ID &&
    showEditorXNotification &&
    experiments.enabled(EXPERIMENTS.SPECS.EDITOR_X_NOTIFICATION);

  const onArticleClick = async (article: Article, order: number) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.CATEGORY,
        kb_lang: locale as string,
        item_id: category.id,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.ARTICLE_SELECTION,
        clicked_url: article.url,
        clicked_text: article.title,
        clicked_item_order: `${order + 1}`,
        clicked_item_id: article.id,
      })
    );
  };

  const articles =
    categoryArticles?.filter(
      (article) => article.type === ArticleType.ARTICLE
    ) || [];
  const knownIssues =
    categoryArticles?.filter(
      (article) => article.type === ArticleType.KNOWN_ISSUE
    ) || [];
  const featureRequests =
    (categoryArticles?.filter((article) => !(isExcludeLaunchedFRsEnabled && isMonthOldLaunchedFr(article)))?.filter(
      (article) => article.type === ArticleType.FEATURE_REQUEST
    ) || []);

  const { name } = category;
  const isCategoryEmpty = categoryArticles?.length === 0;
  return (
    <Box
      direction="vertical"
      className={css.wrapper}
      dataHook={DATA_HOOKS.CATEGORY_ARTICLES}
    >
      <Box
        direction="vertical"
        className={`${css.innerWrapper} ${isEditorXSubCategory ? css.wixEditor : ''
          }`}
      >
        <Heading
          size="small"
          className={css.title}
          as="h1"
          dataHook={DATA_HOOKS.CATEGORY_ARTICLES_TITLE}
        >
          {name}
        </Heading>

        {isEditorXSubCategory && (
          <Box className={css.floatingNotificationEditorX}>
            <EditorXNotification
              onClose={() => setShowEditorXNotification(false)}
            />
          </Box>
        )}
        {categoryArticles ? (
          <>
            <ArticleListSection
              type={ArticleType.ARTICLE}
              articles={articles}
              onClick={onArticleClick}
              startIndex={0}
              isEditorXSubCategory={isEditorXSubCategory}
            />
            <ArticleListSection
              type={ArticleType.KNOWN_ISSUE}
              articles={knownIssues}
              startIndex={articles.length}
              onClick={onArticleClick}
              isEditorXSubCategory={isEditorXSubCategory}
            />
            <ArticleListSection
              type={ArticleType.FEATURE_REQUEST}
              articles={featureRequests}
              startIndex={knownIssues.length + articles.length}
              onClick={onArticleClick}
              isEditorXSubCategory={isEditorXSubCategory}
            />
          </>
        ) : (
          <Box
            align="center"
            verticalAlign="middle"
            className={css.loaderWrapper}
            width="100%"
            marginTop="42px"
          >
            <Loader size="medium" />
          </Box>
        )}

        {isCategoryEmpty ? <EmptyCategoryState /> : null}
      </Box>
    </Box>
  );
};
