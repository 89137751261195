import { Category } from '@wix/answers-api';
import { Box, Heading, Text } from '@wix/design-system';
import { FunctionComponent } from 'react';
import css from './index.module.scss';
import { DATA_HOOKS } from '../../../dataHooks';

export type CategoryPageHeaderProps = {
  selectedCategory: Category;
};

export const CategoryPageHeader: FunctionComponent<CategoryPageHeaderProps> = ({
  selectedCategory,
}) =>
  selectedCategory.description ? (
    <Box
      className={`${css.categoryHeader} ${
        css[`category-${selectedCategory.id}`]
      }`}
      direction="vertical"
      verticalAlign="middle"
      dataHook={DATA_HOOKS.CATEGORY_HEADER}
    >
      <Heading appearance="H1" className={css.categoryTitle}>
        {selectedCategory?.name}
      </Heading>
      <Text size="small" className={css.categoryDescription}>
        {selectedCategory.description}
      </Text>
    </Box>
  ) : (
    <Heading appearance="H1" className={css.categoryTitle}>
      {selectedCategory?.name}
    </Heading>
  );
