import { FunctionComponent } from 'react';
import { Box, SkeletonGroup, SkeletonRectangle } from '@wix/design-system';
import css from './index.module.scss';
import { DATA_HOOKS } from '../../../../dataHooks';

export type SubcategoryCardLoaderProps = {
  totalItems: number;
};

export const SubcategoryCardLoader: FunctionComponent<
  SubcategoryCardLoaderProps
> = ({ totalItems }: SubcategoryCardLoaderProps) => {
  const itemsToShow = totalItems > 3 ? 3 : totalItems;
  return (
    <Box
      direction="vertical"
      className={css.wrapper}
    >
      <SkeletonGroup skin="light" className={css.skeletonGroup}  dataHook={DATA_HOOKS.SUBCATEGORY_CARD_SKELTON}>
        {Array.from({ length: itemsToShow }, (_, index) => (
          <Box
            key={index}
            className={css.cardSkeleton}
            marginTop="16px"
            dataHook={DATA_HOOKS.SUBCATEGORY_CARD_ITEM_SKELTON}
          >
            <SkeletonRectangle height="12px" width="15px" />
            <SkeletonRectangle
              height="12px"
              width="100%"
              className={css.skeletonLine}
            />
          </Box>
        ))}

        {totalItems > 3 ? (
          <Box
            width="100%"
            marginTop="27px"
            className={css.cardFooterSkeleton}
            dataHook={DATA_HOOKS.SUBCATEGORY_CARD_FOOTER_SKELTON}
          >
            <SkeletonRectangle height="12px"  width="121px" />
            <SkeletonRectangle height="12px" width="48px" />
          </Box>
        ) : (
          <Box width="100%" marginTop="27px" className={css.cardFooterSkeleton}>
            <SkeletonRectangle width="175px" height='12px' />
          </Box>
        )}
      </SkeletonGroup>
    </Box>
  );
};
