export const ArrowDownRightIcon = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 5.5C4.77614 5.5 5 5.72386 5 6V9C5 9.82843 5.67157 10.5 6.5 10.5H12.2929L9.96447 8.17157C9.7692 7.97631 9.7692 7.65973 9.96447 7.46447C10.1597 7.2692 10.4763 7.2692 10.6716 7.46447L13.8536 10.6464C14.0488 10.8417 14.0488 11.1583 13.8536 11.3536L10.6716 14.5355C10.4763 14.7308 10.1597 14.7308 9.96447 14.5355C9.7692 14.3403 9.7692 14.0237 9.96447 13.8284L12.2929 11.5H6.5C5.11929 11.5 4 10.3807 4 9V6C4 5.72386 4.22386 5.5 4.5 5.5Z"
      fill="currentcolor"
    />
  </svg>
);
