export const ArrowDownLeftIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 5C13.2239 5 13 5.22386 13 5.5V8.5C13 9.32843 12.3284 10 11.5 10H5.70711L8.03553 7.67157C8.2308 7.47631 8.2308 7.15973 8.03553 6.96447C7.84027 6.7692 7.52369 6.7692 7.32843 6.96447L4.14645 10.1464C3.95118 10.3417 3.95118 10.6583 4.14645 10.8536L7.32843 14.0355C7.52369 14.2308 7.84027 14.2308 8.03553 14.0355C8.2308 13.8403 8.2308 13.5237 8.03553 13.3284L5.70711 11H11.5C12.8807 11 14 9.88071 14 8.5V5.5C14 5.22386 13.7761 5 13.5 5Z"
      fill="currentcolor"
    />
  </svg>
);
