import { useEffect, useState } from 'react';
import { DATA_HOOKS } from '../dataHooks';

export const useEditorXNotificationHeight = (): [number] => {
  const [editorXNotificationHeight, setEditorXNotificationHeight] = useState(0);

  useEffect(() => {
    const height =
      (
        document.body.querySelector(
          `[data-hook="${DATA_HOOKS.EDITOR_X_NOTIFICATION}"]`
        ) as HTMLElement
      )?.offsetHeight || 0;
    setEditorXNotificationHeight(height);
  }, []);

  return [editorXNotificationHeight];
};
