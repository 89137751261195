import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { Box, Text } from '@wix/design-system';
import { DATA_HOOKS } from '../../../../dataHooks';
import css from './index.module.scss';
import { InnerLink } from '../../../InnerLink';
import { TOOLTIP_ENTER_DELAY } from '../../../../constants';

export type SubcategoryCardFooterProps = {
  additionalItems: number;
  highlighted: 'articles' | 'subcategories';
  categoryUri: string;
  onClickSeeAll: () => void;
};

export const SubcategoryCardFooter: FunctionComponent<
  SubcategoryCardFooterProps
> = ({
  additionalItems,
  highlighted,
  categoryUri,
  onClickSeeAll,
}: SubcategoryCardFooterProps) => {
  const { t } = useTranslation();

  const label =
    additionalItems > 0
      ? t(`category-page.subcategory-card.more-items.${highlighted}`, {
          count: additionalItems,
        })
      : t(`category-page.subcategory-card.no-more-items.${highlighted}`);

  return (
    <Box
      className={css.wrapper}
      align="space-between"
      direction="horizontal"
      dataHook={DATA_HOOKS.SUBCATEGORY_CARD_FOOTER}
    >
      <Text
        ellipsis
        size="small"
        dataHook={DATA_HOOKS.SUBCATEGORY_CARD_CATEGORY_ELEMENTS_COUNT}
        className={css.moreItems}
        tooltipProps={{ enterDelay: TOOLTIP_ENTER_DELAY }}
      >
        {label}
      </Text>
      {additionalItems > 0 && (
        <InnerLink
          href={categoryUri}
          dataHook={DATA_HOOKS.SUBCATEGORY_CARD_CATEGORY_SEE_ALL}
          onClick={onClickSeeAll}
          className={css.seeAllLink}
          shallow
        >
          <Text
            weight="thin"
            size="small"
            dataHook={`${DATA_HOOKS.SUBCATEGORY_CARD_CATEGORY_SEE_ALL}-text`}
            className={css.seeAll}
          >
            {t('category-page.subcategory-card.see-all')}
          </Text>
        </InnerLink>
      )}
    </Box>
  );
};
