export const ArticleMediumIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 14V10C18 8.57473 17.9988 7.58104 17.9209 6.81505C17.8446 6.06578 17.7034 5.64604 17.4944 5.33329C17.2755 5.00572 16.9943 4.72447 16.6667 4.50559C16.354 4.29662 15.9342 4.15535 15.1849 4.07913C14.419 4.00121 13.4253 4 12 4C10.5747 4 9.58104 4.00121 8.81505 4.07913C8.06578 4.15535 7.64604 4.29662 7.33329 4.50559C7.00572 4.72447 6.72447 5.00572 6.50559 5.33329C6.29662 5.64604 6.15535 6.06578 6.07913 6.81505C6.00121 7.58104 6 8.57473 6 10V14C6 15.4253 6.00121 16.419 6.07913 17.1849C6.15535 17.9342 6.29662 18.354 6.50559 18.6667C6.72447 18.9943 7.00572 19.2755 7.33329 19.4944C7.64604 19.7034 8.06578 19.8446 8.81505 19.9209C9.58104 19.9988 10.5747 20 12 20C13.4253 20 14.419 19.9988 15.1849 19.9209C15.9342 19.8446 16.354 19.7034 16.6667 19.4944C16.9943 19.2755 17.2755 18.9943 17.4944 18.6667C17.7034 18.354 17.8446 17.9342 17.9209 17.1849C17.9988 16.419 18 15.4253 18 14ZM5.67412 4.77772C5 5.78661 5 7.19108 5 10V14C5 16.8089 5 18.2134 5.67412 19.2223C5.96596 19.659 6.34096 20.034 6.77772 20.3259C7.78661 21 9.19108 21 12 21C14.8089 21 16.2134 21 17.2223 20.3259C17.659 20.034 18.034 19.659 18.3259 19.2223C19 18.2134 19 16.8089 19 14V10C19 7.19108 19 5.78661 18.3259 4.77772C18.034 4.34096 17.659 3.96596 17.2223 3.67412C16.2134 3 14.8089 3 12 3C9.19108 3 7.78661 3 6.77772 3.67412C6.34096 3.96596 5.96596 4.34096 5.67412 4.77772Z"
      fill="#000000"
    />
    <line
      x1="8.5"
      y1="6.5"
      x2="13.5"
      y2="6.5"
      stroke="#000000"
      strokeLinecap="round"
    />
    <line
      x1="8.5"
      y1="9.5"
      x2="11.5"
      y2="9.5"
      stroke="#000000"
      strokeLinecap="round"
    />
  </svg>
);
