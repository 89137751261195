import { Article, Category } from '@wix/answers-api';
import { FunctionComponent, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Box, Heading, Text } from '@wix/design-system';
import { DATA_HOOKS } from '../../../dataHooks';
import { SubcategoryCardFooter } from './SubcategoryCardFooter/Index';
import { LeftNavClickEventInfo } from '..';
import { InnerLink } from '../../InnerLink';
import { ClickedItemType } from '../../../types';
import { ArrowDownRightIcon } from '../../../icons/ArrowDownRightIcon';
import { ArrowDownLeftIcon } from '../../../icons/ArrowDownLeftIcon';
import { isRTL } from '../../../utils/internationalization';
import css from './index.module.scss';
import { ArticleSmallIcon } from '../../../icons/ArticleSmallIcon';
import { SubcategoryCardLoader } from './SubcategoryCardLoader/Index';
import { TOOLTIP_ENTER_DELAY } from '../../../constants';

export type SubcategoryCardProps = {
  category: Category;
  categoryOrder: number;
  articles?: Article[];
  onClickSubcategory: (
    category: Category,
    event: LeftNavClickEventInfo
  ) => void;
  onClickArticle: (article: Article, event: LeftNavClickEventInfo) => void;
};

export const ELEMENTS_LIMIT = 3;

export const SubcategoryCard: FunctionComponent<SubcategoryCardProps> = ({
  category,
  categoryOrder,
  articles,
  onClickSubcategory,
  onClickArticle,
}: SubcategoryCardProps) => {
  const { locale } = useRouter();
  const isRTLLanguage = isRTL(locale);
  const hasCategories = category.children && category.children.length > 0;
  const onClickCategoryEvent = useCallback(
    async (
      clickedCategory: Category,
      itemType: ClickedItemType,
      clickedItemOrder?: number
    ) => {
      onClickSubcategory(clickedCategory, {
        clicked_item_type: itemType,
        clicked_url: clickedCategory.url,
        clicked_text: clickedCategory.name,
        category_order: categoryOrder,
        clicked_item_id: clickedCategory.id,
        clicked_item_order: clickedItemOrder?.toString(),
      });
    },
    [categoryOrder, onClickSubcategory]
  );

  const onClickArticleEvent = useCallback(
    async (clickedArticle: Article, clickedItemOrder: number) => {
      onClickArticle(clickedArticle, {
        clicked_item_type: 'article_selection',
        clicked_url: clickedArticle.url,
        clicked_text: clickedArticle.title,
        category_order: categoryOrder,
        clicked_item_id: clickedArticle.id,
        clicked_item_order: clickedItemOrder.toString(),
      });
    },
    [categoryOrder, onClickArticle]
  );

  const counter = hasCategories
    ? category.children?.length || 0
    : category.publishedArticleCount || 0;
  const highlighted = hasCategories ? 'subcategories' : 'articles';
  const slicedSubcategories =
    category.children && category.children.slice(0, ELEMENTS_LIMIT);
  const slicedCategoryArticles = articles
    ?.sort((articleA, articleB) => articleA.type - articleB.type)
    .slice(0, ELEMENTS_LIMIT);
  const tooltipProps = {
    enterDelay: TOOLTIP_ENTER_DELAY,
  };
  return (
    <Box
      className={css.wrapper}
      direction="vertical"
      dataHook={DATA_HOOKS.SUBCATEGORY_CARD}
    >
      <InnerLink
        href={category.uri}
        dataHook={DATA_HOOKS.SUBCATEGORY_CARD_TITLE}
        withEnterHandler
        shallow
        onClick={() => onClickCategoryEvent(category, 'category_selection')}
      >
        <Heading ellipsis size="small" appearance="H3" className={css.title}>
          {category.name}
        </Heading>
      </InnerLink>
      {hasCategories ? (
        slicedSubcategories && (
          <Box
            dataHook={DATA_HOOKS.SUBCATEGORY_CARD_SUBCATEGORIES}
            className={css.subcategories}
            direction="vertical"
          >
            {slicedSubcategories.map((subcategory, idx) => (
              <li key={subcategory.id}>
                <InnerLink
                  onClick={() =>
                    onClickCategoryEvent(
                      subcategory,
                      'sub_category_selection',
                      idx + 1
                    )
                  }
                  href={subcategory.uri}
                  className={css.subcategory}
                  withEnterHandler
                  shallow
                  dataHook={DATA_HOOKS.SUBCATEGORY_CARD_SUBCATEGORY}
                >
                  {isRTLLanguage ? (
                    <ArrowDownLeftIcon />
                  ) : (
                    <ArrowDownRightIcon />
                  )}
                  <Text
                    tooltipProps={tooltipProps}
                    ellipsis
                    size="medium"
                    dataHook={DATA_HOOKS.SUBCATEGORY_CARD_CATEGORY_TITLE}
                    className={css.subcategoryTitle}
                  >
                    {subcategory.name}
                  </Text>
                </InnerLink>
              </li>
            ))}
          </Box>
        )
      ) : !!articles && (
        <Box
          dataHook={DATA_HOOKS.SUBCATEGORY_CARD_ARTICLES}
          className={css.articles}
          direction="vertical"
        >
          {slicedCategoryArticles &&
            slicedCategoryArticles.map((article, idx) => (
              <li key={article.id}>
                <InnerLink
                  onClick={() => onClickArticleEvent(article, idx + 1)}
                  key={article.id}
                  href={article.uri}
                  className={css.article}
                  withEnterHandler
                  dataHook={DATA_HOOKS.SUBCATEGORY_CARD_ARTICLE}
                >
                  <ArticleSmallIcon />
                  <Text
                    tooltipProps={tooltipProps}
                    ellipsis
                    size="medium"
                    dataHook={DATA_HOOKS.SUBCATEGORY_CARD_ARTICLE_TITLE}
                    className={css.articleTitle}
                  >
                    {article.title}
                  </Text>
                </InnerLink>
              </li>
            ))}
        </Box>
      )}
      {articles || hasCategories ? (
        <SubcategoryCardFooter
          additionalItems={counter - ELEMENTS_LIMIT}
          highlighted={highlighted}
          onClickSeeAll={() => onClickCategoryEvent(category, 'see_all')}
          categoryUri={category.uri}
        />
      ) : (
       <SubcategoryCardLoader totalItems={counter} />
      )}
    </Box>
  );
};
