import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';

export const EmptyCategoryState: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box
      direction="vertical"
      align="center"
      verticalAlign="middle"
      className={css.EmptyCategoryStateWrapper}
      dataHook={DATA_HOOKS.EMPTY_CATEGORY_STATE}
    >
      <Heading
        size="small"
        textAlign="center"
        className={css.EmptyCategoryStateTitle}
        dataHook={DATA_HOOKS.EMPTY_CATEGORY_STATE_TITLE}
      >
        {t('category-page.empty-state.title')}
      </Heading>
      <Text
        size="medium"
        textAlign="center"
        className={css.EmptyCategoryStateDescription}
        dataHook={DATA_HOOKS.EMPTY_CATEGORY_STATE_DESCRIPTION}
      >
        {t('category-page.empty-state.description')}
      </Text>
    </Box>
  );
};
