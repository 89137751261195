import { Article, Category } from '@wix/answers-api';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, CloseButton } from '@wix/design-system';
import { DATA_HOOKS } from '../../dataHooks';
import { getCategoriesPaths } from '../../utils/categories';
import { NavCategoryNode } from './NavCategoryNode';
import { LeftNavClickEventInfo } from '../CategoryPageWrapper';
import css from './index.module.scss';
import { ResizerHorizontal } from './Resizer';

export type NavigationTopics = Category & {
  articles?: () => Promise<Article[]>;
};

export type NavCategoryTreeProps = {
  topics: NavigationTopics[];
  selectedCategoryId: string;
  isCategoryMenuOpen?: boolean;
  locale?: string;

  onCategoryChange: (
    category: Category,
    eventData: LeftNavClickEventInfo
  ) => Promise<void>;
  onArticleClick: (
    article: Article,
    eventData: LeftNavClickEventInfo
  ) => Promise<void>;
  onNodeToggle: (
    category: Category,
    eventData: LeftNavClickEventInfo
  ) => Promise<void>;
  onCategoryMenuClose?: () => void;
};

export const NavCategoryTree: FunctionComponent<NavCategoryTreeProps> = ({
  topics,
  selectedCategoryId,
  isCategoryMenuOpen,
  locale,
  onCategoryChange,
  onArticleClick,
  onNodeToggle,
  onCategoryMenuClose,
}) => {
  const calcExpanded = useCallback(
    (currExpanded: string[]): string[] => {
      const path = getCategoriesPaths(topics, [selectedCategoryId]);
      const set = new Set(currExpanded);
      path[selectedCategoryId]
        .filter((pathItem) => !!pathItem)
        .forEach((category) => {
          set.add(category.id);
        });
      return Array.from(set);
    },
    [selectedCategoryId]
  );
  const [expanded, setExpanded] = useState<string[]>(calcExpanded([]));
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!expanded.includes(selectedCategoryId)) {
      setExpanded(calcExpanded(expanded));
    }
  }, [selectedCategoryId, calcExpanded]);

  const toggleCategory = useCallback(
    async (category: Category, order: number) => {
      const isExpanded = expanded.includes(category.id);

      await onNodeToggle(category, {
        clicked_item_type: `${
          !isExpanded ? 'expanded' : 'collapsed'
        }_category_in_navigation_bar`,
        clicked_text: category.name,
        clicked_item_order: order.toString(),
        clicked_item_id: category.id,
      });

      if (isExpanded) {
        setExpanded(expanded.filter((e) => e !== category.id));
      } else {
        setExpanded([...expanded, category.id]);
      }
    },
    [expanded, onNodeToggle]
  );

  const onCategoryChangeEvent = async (category: Category, order: number) => {
    if (category.id !== selectedCategoryId) {
      await onCategoryChange(category, {
        clicked_item_type: 'category_selection_navigation_bar',
        clicked_url: category.url,
        clicked_text: category.name,
        clicked_item_order: order.toString(),
        clicked_item_id: category.id,
      });
    }
  };

  const onArticleClickEvent = async (article: Article, order: number) => {
    await onArticleClick(article, {
      clicked_item_type: 'article_selection_navigation_bar',
      clicked_url: article.url,
      clicked_text: article.title,
      clicked_item_order: order.toString(),
      clicked_item_id: article.id,
    });
  };

  return (
    <Box direction="horizontal" className={css.resizerWrapper}>
      <div
        ref={elementRef}
        className={`${css.wrapper} ${isCategoryMenuOpen ? css.open : ''}`}
      >
        <Box
          dataHook={DATA_HOOKS.NAV_CATEGORY_TREE}
          className={css.navCategoryTree}
          direction="vertical"
        >
          {topics.map((topic, idx) => (
            <NavCategoryNode
              order={idx + 1}
              category={topic}
              isRoot={true}
              key={topic.id}
              onArticleClick={onArticleClickEvent}
              onCategoryToggle={toggleCategory}
              expandedCategories={expanded}
              onCategoryChange={onCategoryChangeEvent}
              selectedCategoryId={selectedCategoryId}
            />
          ))}
          <CloseButton
            className={css.closeButton}
            skin="light"
            size="large"
            onClick={onCategoryMenuClose}
          />
        </Box>
      </div>
      <ResizerHorizontal
        categoryId={selectedCategoryId}
        elemRef={elementRef}
        locale={locale}
      />
    </Box>
  );
};
